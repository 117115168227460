import React, { useEffect, useState } from 'react'
import {
  Box,
  FormLabel,
  Grid,
  Modal,
  styled,
  Typography,
  Paper,
  CircularProgress,
  LinearProgress,
  linearProgressClasses,
  TextField,
} from '@mui/material'
import { BorderButtonPurple, CustomButton } from '../buttons'
import lightTheme from '../../styles/theme/lightTheme'
import '../../styles/components/_box.scss'
import '../../styles/components/_grids.scss'
import '../../styles/components/_inputs.scss'
import {
  truncate,
  unserializeCompetenceLocal,
  unserializeLocal,
} from '../../config'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faCalendarPlus,
  faChainBroken,
  faCircleCheck,
  faCircleExclamation,
  faClose,
  faHeart,
  faHeartBroken,
} from '@fortawesome/pro-solid-svg-icons'
import 'dayjs/locale/fr'
import 'dayjs/locale/en'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup/dist/yup'
import * as yup from 'yup'
import Api from '../../services/api.service'
import { handlingErrors } from '../../utils'
import { toast, ToastContainer, Slide } from 'react-toastify'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import {
  disponibilitesList2,
  disponibleSousList2,
  niveauCompetencesList,
  niveauLanguesList,
} from '../../data'
import moment from 'moment-timezone'
import dayjs from 'dayjs'
import { format } from 'date-fns'
import { ButtonRadioInput } from '../inputs'

const ModalContainer = styled(Box)(({ theme }) => ({
  width: 'calc(100% - 50px)',
  height: 650,
  backgroundColor: '#FFFFFF',
  position: 'absolute',
  top: '10%',
  left: '50%',
  transform: 'translate(-50%, 0%)',
  padding: '30px',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  rowGap: '20px',

  '&:focus-visible': {
    outline: 'none',
  },

  [theme.breakpoints.up('sm')]: {
    width: 700,
    /*height: 500,*/
    height: 'auto',
    rowGap: '35px',
  },
}))

const BoxContainer = styled(Box)(({ theme }) => ({
  width: '100%',
  height: 500,
  backgroundColor: lightTheme.palette.primary.b6,
  padding: '20px',

  [theme.breakpoints.up('sm')]: {
    /*height: 300,*/
    height: 'auto',
  },
}))

const ItemLong = styled(Paper)(({ theme }) => ({
  backgroundColor: '#FFFFFF',
  padding: '15px',
}))

const TitleContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
}))

const TitleBox = styled(Typography)(({ theme }) => ({
  fontFamily: 'Century Gothic Bold',
  fontSize: 14,
  lineHeight: '140%',
  letterSpacing: '0.2px',
  color: lightTheme.palette.text.primary,
  textTransform: 'uppercase',
}))

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 10,
  borderRadius: 5,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor:
      theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800],
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: theme.palette.mode === 'light' ? '#96A0FF' : '#308fe8',
  },
}))

const schema = yup.object({
  /* lien: yup.string().required('Merci de renseigner ce champ.'), */
})

const DetailCandidat = ({
  open,
  onClose,
  onSuccess,
  candidat,
  companyId,
  poste,
  besoinEntrepriseId,
  isAdmin = false,
}) => {
  console.log(
    typeof JSON.parse(candidat.niveau_langue),
    JSON.parse(candidat.niveau_langue)[0].langue
  )
  const { t, i18n } = useTranslation()
  const currentUser = useSelector((state) => state.userAuth.user)
  const [loading, setLoading] = useState(false)
  const [loadingMatch, setLoadingMatch] = useState(false)
  const [step, setStep] = useState(1)
  const [errorDate, setErrorDate] = useState(false)
  const [errorTime, setErrorTime] = useState(false)
  const [isFavorie, setIsFavorie] = useState(false)
  const [idFavori, setIdFavori] = useState(null)
  const [seeMore, setSeeMore] = useState(false)
  const handleSeeMore = (event) => {
    setSeeMore(!seeMore)
  }
  const [loadingSlots, setLoadingSlots] = useState(false)
  const [slots, setSlots] = useState([])
  const [times, setTimes] = useState('')

  const currentDate = new Date()
  const tomorrowDate = new Date()
  tomorrowDate.setDate(currentDate.getDate() + 1)
  const [dateValue, setDateValue] = useState(dayjs(tomorrowDate))
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  })

  useEffect(() => {
    chackFavorie()
  }, [candidat.id])

  const chackFavorie = async () => {
    try {
      const result = await Api.favoris.listWithFilter({
        utilisateur: currentUser.data.id,
        favori: candidat.id,
      })
      const res = handlingErrors(result)

      if (res.ok) {
        if (res.data.length > 0) {
          setIsFavorie(true)
          setIdFavori(res.data[0].id)
        }
      }

      if (res.status === 401) {
        toast.error(t('successMessage.sessionExpired'), {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'light',
          icon: ({ theme, type }) => (
            <FontAwesomeIcon
              icon={faCircleExclamation}
              fontSize={25}
              color={lightTheme.palette.error.main}
            />
          ),
        })
        window.scrollTo(0, 0)
        return
      }
    } catch (error) {
      toast.error(t('successMessage.somethingWrong'), {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'light',
        icon: ({ theme, type }) => (
          <FontAwesomeIcon
            icon={faCircleCheck}
            fontSize={25}
            color={lightTheme.palette.success.main}
          />
        ),
      })
    }
  }

  const removeCandidatMatch = async () => {
    let data = {
      ids: [isAdmin ? candidat.profile_id : candidat.id],
    }

    /* console.log(besoinEntrepriseId, data)
    return */
    setLoadingMatch(true)
    //return

    try {
      const result = await Api.besoinEntreprise.edit(besoinEntrepriseId, data)
      const res = handlingErrors(result)

      if (res.ok) {
        toast.success(t('successMessage.candidateRemoveMatch'), {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'light',
          icon: ({ theme, type }) => (
            <FontAwesomeIcon
              icon={faCircleCheck}
              fontSize={25}
              color={lightTheme.palette.success.main}
            />
          ),
        })
        onSuccess()
      }

      if (res.status === 401) {
        toast.error(t('successMessage.sessionExpired'), {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'light',
          icon: ({ theme, type }) => (
            <FontAwesomeIcon
              icon={faCircleExclamation}
              fontSize={25}
              color={lightTheme.palette.error.main}
            />
          ),
        })
        window.scrollTo(0, 0)
        return
      }
    } catch (error) {
      toast.error(t('successMessage.somethingWrong'), {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'light',
        icon: ({ theme, type }) => (
          <FontAwesomeIcon
            icon={faCircleExclamation}
            fontSize={25}
            color={lightTheme.palette.error.main}
          />
        ),
      })
    }
    setLoadingMatch(false)
  }

  const addToFavorie = async () => {
    let data = {}
    data.utilisateur = '/api/utilisateurs/' + currentUser.data.id
    data.favori = '/api/utilisateurs/' + candidat.id

    //console.log(data, currentUser, candidat)
    //return
    setLoading(true)
    //return

    try {
      const result = await Api.favoris.add(data)
      const res = handlingErrors(result)

      if (res.ok) {
        setIsFavorie(true)
        setIdFavori(res.data.id)
        toast.success(t('successMessage.candidateAddedToFavorite'), {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'light',
          icon: ({ theme, type }) => (
            <FontAwesomeIcon
              icon={faCircleCheck}
              fontSize={25}
              color={lightTheme.palette.success.main}
            />
          ),
        })
      }

      if (res.status === 401) {
        toast.error(t('successMessage.sessionExpired'), {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'light',
          icon: ({ theme, type }) => (
            <FontAwesomeIcon
              icon={faCircleExclamation}
              fontSize={25}
              color={lightTheme.palette.error.main}
            />
          ),
        })
        window.scrollTo(0, 0)
        return
      }
    } catch (error) {
      toast.error(t('successMessage.somethingWrong'), {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'light',
        icon: ({ theme, type }) => (
          <FontAwesomeIcon
            icon={faCircleExclamation}
            fontSize={25}
            color={lightTheme.palette.error.main}
          />
        ),
      })
    }
    setLoading(false)
  }

  const removeFromFavorie = async () => {
    setLoading(true)

    try {
      const result = await Api.favoris.delete(idFavori)
      const res = handlingErrors(result)

      if (res.ok) {
        setIsFavorie(false)
        setIdFavori(null)
        toast.success(t('successMessage.candidateRemovedFromFavorite'), {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'light',
          icon: ({ theme, type }) => (
            <FontAwesomeIcon
              icon={faCircleCheck}
              fontSize={25}
              color={lightTheme.palette.success.main}
            />
          ),
        })
      }

      if (res.status === 401) {
        toast.error(t('successMessage.sessionExpired'), {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'light',
          icon: ({ theme, type }) => (
            <FontAwesomeIcon
              icon={faCircleExclamation}
              fontSize={25}
              color={lightTheme.palette.error.main}
            />
          ),
        })
        window.scrollTo(0, 0)
        return
      }
    } catch (error) {
      toast.error(t('successMessage.somethingWrong'), {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'light',
        icon: ({ theme, type }) => (
          <FontAwesomeIcon
            icon={faCircleExclamation}
            fontSize={25}
            color={lightTheme.palette.error.main}
          />
        ),
      })
    }
    setLoading(false)
  }

  const onSubmit = async (data) => {
    if (dateValue) {
      setErrorDate(false)
    } else {
      setErrorDate(true)
    }

    if (times) {
      setErrorTime(false)
    } else {
      setErrorTime(true)
    }

    if (!dateValue || !times) return

    const DateValue = format(new Date(dateValue), 'yyyy-MM-dd')

    data.dateDebut = `${DateValue}T${times}`
    data.dateFin = `${DateValue}T${times}`
    data.detail = 'Proposition RDV'
    data.type = 'rdv_entreprise'
    data.entreprise = '/api/entreprises/' + companyId
    data.candidat = '/api/candidats/' + candidat.idcandidat

    /* {
      "dateDebut": "2022-10-05T15:05:22.211Z",
      "dateFin": "2022-10-05T15:05:30.211Z",
      "type": "rdv_decouverte",
      "detail": "Detail de l’événement",
      "lien": "",
      "entreprise": "/api/entreprises/8",
      "candidat": "/api/candidats/2"
    } */
    setLoading(true)
    //return

    try {
      const result = await Api.calendar.add(data)
      const res = handlingErrors(result)

      if (res.ok) {
        toast.success(t('successMessage.appointmentRequestSent'), {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'light',
          icon: ({ theme, type }) => (
            <FontAwesomeIcon
              icon={faCircleCheck}
              fontSize={25}
              color={lightTheme.palette.success.main}
            />
          ),
        })
      }

      if (res.status === 401) {
        toast.error(t('successMessage.sessionExpired'), {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'light',
          icon: ({ theme, type }) => (
            <FontAwesomeIcon
              icon={faCircleExclamation}
              fontSize={25}
              color={lightTheme.palette.error.main}
            />
          ),
        })
        window.scrollTo(0, 0)
        return
      }
    } catch (error) {
      toast.error(t('successMessage.somethingWrong'), {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'light',
        icon: ({ theme, type }) => (
          <FontAwesomeIcon
            icon={faCircleExclamation}
            fontSize={25}
            color={lightTheme.palette.error.main}
          />
        ),
      })
    }
    setLoading(false)
    onClose()
  }

  const handleChangeButtonRadio = (inputValue) => {
    setTimes(inputValue)
  }

  useEffect(() => {
    getDateDisponible()
  }, [dateValue])

  const getDateDisponible = async () => {
    setLoadingSlots(true)
    setTimes('')
    const DateFormat = format(new Date(dateValue), 'yyyy-MM-dd')

    try {
      let result = await Api.calendar.listDIspoEntreprisePerDate(
        DateFormat,
        currentUser.data.id
      )
      const handling = handlingErrors(result)

      if (handling.status === 401) {
        setLoadingSlots(false)
        toast.error(t('successMessage.sessionExpired'), {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'light',
          icon: ({ theme, type }) => (
            <FontAwesomeIcon
              icon={faCircleExclamation}
              fontSize={25}
              color={lightTheme.palette.error.main}
            />
          ),
        })
        window.scrollTo(0, 0)
        return
      }

      if (handling.ok) {
        const res = handling.data

        const DateValue = format(new Date(dateValue), 'dd/MM/yyyy')

        setSlots(res[DateValue])
        setLoadingSlots(false)
      } else {
        setLoadingSlots(false)
        window.scrollTo(0, 0)
      }
    } catch (error) {
      setLoading(false)
      toast.error(t('successMessage.somethingWrong'), {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'light',
        icon: ({ theme, type }) => (
          <FontAwesomeIcon
            icon={faCircleExclamation}
            fontSize={25}
            color={lightTheme.palette.error.main}
          />
        ),
      })
      window.scrollTo(0, 0)
    }
  }

  return (
    <>
      <Modal
        open={open}
        sx={{
          '& .MuiBackdrop-root': {
            backgroundColor: 'rgba(51, 51, 51, 0.65)',
          },
          overflow: 'auto',
        }}
      >
        <ModalContainer>
          <Typography
            variant={'h3'}
            textTransform={'uppercase'}
            align={'center'}
          >
            {step === 1
              ? t('box.title.candidateDetail')
              : t('box.title.rdvPoroposition')}
            <br></br>
            #Jober000{candidat?.idcandidat}
            <Box
              sx={{
                position: 'absolute',
                right: '10px',
                top: '10px',
                cursor: 'pointer',
              }}
            >
              <FontAwesomeIcon icon={faClose} fontSize={25} onClick={onClose} />
            </Box>
          </Typography>
          <BoxContainer
            sx={{
              display: step === 1 ? 'flex' : 'none',
            }}
          >
            <Grid
              container
              spacing={2}
              sx={{
                flexDirection: { xs: 'column', sm: 'row' },
              }}
            >
              {isAdmin && (
                <Grid item sm={12} order={{ sm: 1 }}>
                  <ItemLong>
                    <Box>
                      <b>
                        {candidat.prenom} {candidat.nom}
                        <br></br>
                        {candidat.telephone}
                        <br></br>
                        {candidat.email}
                        <br></br>
                        {poste}
                        <br></br>
                        {candidat.ville} / {candidat.pays} /{' '}
                        {candidat.nationalite}
                      </b>
                    </Box>
                  </ItemLong>
                </Grid>
              )}
              <Grid item sm={12} order={{ sm: 1 }}>
                <ItemLong>
                  <TitleContainer>
                    <TitleBox>
                      {t('filter.matching')}{' '}
                      {parseFloat(candidat.matching_percentage).toFixed(2)}%
                    </TitleBox>
                  </TitleContainer>
                  <Box>
                    <BorderLinearProgress
                      variant="determinate"
                      value={parseFloat(candidat.matching_percentage).toFixed(
                        2
                      )}
                    />
                  </Box>
                </ItemLong>
              </Grid>

              <Grid item sm={12} order={{ sm: 1 }}>
                <ItemLong>
                  <TitleContainer>
                    <TitleBox>{t('profile.candidat.descriptif')}</TitleBox>
                  </TitleContainer>
                  <br></br>
                  <Box>
                    {seeMore ? candidat.resume : truncate(candidat.resume)}
                  </Box>
                  {candidat?.resume && candidat?.resume.length > 150 && (
                    <a
                      style={{
                        cursor: 'pointer',
                        color: '#96A0FF',
                        fontWeight: 'bold',
                        textDecoration: 'underline',
                      }}
                      onClick={handleSeeMore}
                    >
                      {seeMore ? t('button.seeLess') : t('button.seeMore')}
                    </a>
                  )}
                </ItemLong>
              </Grid>

              <Grid item sm={12} order={{ sm: 2 }}>
                <ItemLong>
                  <TitleContainer>
                    <TitleBox>{t('filter.place')}</TitleBox>
                  </TitleContainer>
                  <Box>
                    {candidat.ville} / {candidat.pays}
                  </Box>
                </ItemLong>
              </Grid>
              {candidat.type_disponibilite && (
                <Grid item sm={12} order={{ sm: 1 }}>
                  <ItemLong>
                    <TitleContainer>
                      <TitleBox>{t('profile.candidat.disponibilite')}</TitleBox>
                    </TitleContainer>
                    <br></br>
                    <Box>
                      {
                        disponibilitesList2.find(
                          (dispo) => dispo.id == candidat.type_disponibilite
                        ).titre[i18n.language]
                      }

                      {candidat.type_disponibilite == 2 &&
                        candidat.disponible_sous &&
                        disponibleSousList2.find(
                          (dispoS) => dispoS.id == candidat.disponible_sous
                        ).titre[i18n.language]}

                      {candidat.disponible_sous == 4 &&
                        moment(candidat.disponible)
                          .tz('Europe/Paris')
                          .format('DD/MM/YYYY')}
                    </Box>
                  </ItemLong>
                </Grid>
              )}
              <Grid item sm={12} order={{ sm: 2 }}>
                <ItemLong>
                  <TitleContainer>
                    <TitleBox>{t('box.timeWorkType')}</TitleBox>
                  </TitleContainer>
                  <br></br>
                  <Box>
                    {candidat.type_temps_travail === 'partiel'
                      ? t('box.timeWorkType2') +
                        ' (' +
                        candidat.temp_partiel +
                        '%)'
                      : t('box.timeWorkType1')}
                  </Box>
                </ItemLong>
              </Grid>
              {JSON.parse(candidat.niveau_langue).length > 0 &&
                JSON.parse(candidat.niveau_langue)[0].langue !== null && (
                  <Grid item sm={12} order={{ sm: 3 }}>
                    <ItemLong>
                      <TitleContainer>
                        <TitleBox>{t('filter.languages')}</TitleBox>
                      </TitleContainer>
                      <Box>
                        <ul className="list-values-lang">
                          {JSON.parse(candidat.niveau_langue).map(
                            (langue, i) => (
                              <li
                                style={{
                                  width: '100%',
                                  float: 'left',
                                  clear: 'both',
                                  display: 'inline-block',
                                  overflow: 'hidden',
                                  whiteSpace: 'nowrap',
                                }}
                              >
                                {langue.langue} (
                                {niveauLanguesList[langue.niveau - 1].titre})
                              </li>
                            )
                          )}
                        </ul>
                      </Box>
                    </ItemLong>
                  </Grid>
                )}

              <Grid item sm={12} order={{ sm: 4 }}>
                <ItemLong>
                  <TitleContainer>
                    <TitleBox>{t('filter.competences')}</TitleBox>
                  </TitleContainer>
                  <Box>
                    {unserializeCompetenceLocal(candidat.competences) !==
                      '' && (
                      <ul className="list-values-compet">
                        {unserializeCompetenceLocal(candidat.competences).map(
                          (competence, i) => (
                            <li
                              style={{
                                width: '100%',
                                float: 'left',
                                clear: 'both',
                                display: 'inline-block',
                                overflow: 'hidden',
                                whiteSpace: 'nowrap',
                              }}
                            >
                              {competence.competence} :{' '}
                              <b>
                                {
                                  niveauCompetencesList[competence.niveau - 1]
                                    .titre[i18n.language]
                                }
                              </b>
                            </li>
                          )
                        )}
                      </ul>
                    )}
                    {/* {candidat.competences &&
                        candidat.competences.map((competence, i) => (
                          <li key={i}>
                            {competence.competence} :{' '}
                            <b>
                              {
                                niveauCompetencesList[competence.niveau - 1]
                                  .titre[i18n.language]
                              }
                            </b>
                          </li>
                        ))} */}
                    {/*   {unserializeLocal(candidat.competences) &&
                        unserializeLocal(candidat.competences).map(
                          (competence, i) => <li>{competence}</li>
                        )} */}
                  </Box>
                </ItemLong>
              </Grid>

              <Grid item sm={12} order={{ sm: 5 }}>
                <ItemLong>
                  <TitleContainer>
                    <TitleBox>
                      {t('filter.competencesAnnexes')}{' '}
                      {candidat.matching_percentage4
                        ? parseFloat(candidat.matching_percentage4).toFixed(2)
                        : 0}
                      %
                    </TitleBox>
                  </TitleContainer>
                  <Box>
                    <BorderLinearProgress
                      variant="determinate"
                      value={
                        candidat.matching_percentage4
                          ? parseFloat(candidat.matching_percentage4).toFixed(2)
                          : 0
                      }
                    />
                    {unserializeCompetenceLocal(
                      candidat.competences_annexes
                    ) !== '' && (
                      <ul className="list-values-compet">
                        {unserializeCompetenceLocal(
                          candidat.competences_annexes
                        ).map((competence, i) => (
                          <li
                            style={{
                              width: '100%',
                              float: 'left',
                              clear: 'both',
                              display: 'inline-block',
                              overflow: 'hidden',
                              whiteSpace: 'nowrap',
                            }}
                          >
                            {competence.competence} :{' '}
                            <b>
                              {
                                niveauCompetencesList[competence.niveau - 1]
                                  .titre[i18n.language]
                              }
                            </b>
                          </li>
                        ))}
                      </ul>
                    )}
                  </Box>
                </ItemLong>
              </Grid>

              <Grid item sm={12} order={{ sm: 6 }}>
                <ItemLong>
                  <TitleContainer>
                    <TitleBox>
                      {t('filter.softskils')}{' '}
                      {candidat.matching_percentage2
                        ? parseFloat(candidat.matching_percentage2).toFixed(2)
                        : 0}
                      %
                    </TitleBox>
                  </TitleContainer>
                  <Box>
                    <BorderLinearProgress
                      variant="determinate"
                      value={
                        candidat.matching_percentage2
                          ? parseFloat(candidat.matching_percentage2).toFixed(2)
                          : 0
                      }
                    />
                    <ul className="list-values">
                      {unserializeLocal(candidat.softskils) &&
                        unserializeLocal(candidat.softskils).map(
                          (softskil, i) => <li>{softskil}</li>
                        )}
                    </ul>
                  </Box>
                </ItemLong>
              </Grid>

              <Grid item sm={12} order={{ sm: 7 }}>
                <ItemLong>
                  <TitleContainer>
                    <TitleBox>
                      {t('filter.values')}{' '}
                      {candidat.matching_percentage3
                        ? parseFloat(candidat.matching_percentage3).toFixed(2)
                        : 0}
                      %
                    </TitleBox>
                  </TitleContainer>
                  <Box>
                    <BorderLinearProgress
                      variant="determinate"
                      value={
                        candidat.matching_percentage3
                          ? parseFloat(candidat.matching_percentage3).toFixed(2)
                          : 0
                      }
                    />
                    <ul className="list-values">
                      {unserializeLocal(candidat.valeurs) &&
                        unserializeLocal(candidat.valeurs).map((valeur, i) => (
                          <li>{valeur}</li>
                        ))}
                    </ul>
                  </Box>
                </ItemLong>
              </Grid>
            </Grid>
          </BoxContainer>
          <BoxContainer
            sx={{
              display: step === 2 ? 'flex' : 'none',
            }}
          >
            <Grid
              container
              spacing={2}
              sx={{
                flexDirection: { xs: 'column', sm: 'row' },
              }}
            >
              {/* <LocalizationProvider
                dateAdapter={AdapterDayjs}
                adapterLocale={i18n.language}
              > */}
              {/* <Grid
                  item
                  xs={12}
                  sm={6}
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    rowGap: '5px',
                  }}
                >
                  <FormLabel className={'labelForm'}>
                    {t('profile.date')}
                  </FormLabel>

                  <TextField
                    type="datetime-local"
                    onChange={changeDate}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    style={{
                      width: '100%',
                      height: '100%',
                      borderRadius: '5px 0 0 5px',
                      border: '1px solid #9E9E9E',
                      padding: '10px 15px',
                      fontFamily: 'Century Gothic',
                      fontSize: 14,
                      lineHeight: '140%',
                      letterSpacing: '0.2px',
                      color: lightTheme.palette.text.primary,
                      backgroundColor: '#FFFFFF',
                    }}
                  />

                  {errorTime && (
                    <Typography className={'error-message'}>
                      {t('errorMessage.date')}
                    </Typography>
                  )}
                </Grid> */}
              <Grid
                item
                xs={12}
                sm={6}
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  rowGap: '5px',
                }}
              >
                <Typography>
                  <b>{t(`contact.date`)}</b>
                </Typography>
                <LocalizationProvider
                  dateAdapter={AdapterDayjs}
                  adapterLocale={i18n.language}
                >
                  <DatePicker
                    label="Custom input"
                    disablePast
                    inputFormat={'DD/MM/YYYY'}
                    value={dateValue}
                    minDate={tomorrowDate}
                    onChange={(newValue) => {
                      setDateValue(newValue)
                    }}
                    renderInput={({ inputRef, inputProps, InputProps }) => (
                      <Box
                        sx={{
                          display: 'flex',
                          alignItems: 'center',
                          width: '100%',
                          height: '45px',
                          backgroundColor: lightTheme.palette.primary.b2,
                          borderRadius: '5px',

                          '& .css-1laqsz7-MuiInputAdornment-root': {
                            height: '100%',
                            width: '50px',
                            margin: 0,
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',

                            '& .css-1yq5fb3-MuiButtonBase-root-MuiIconButton-root':
                              {
                                margin: 0,
                                color: '#FFFFFF',
                              },
                          },
                        }}
                      >
                        <input
                          ref={inputRef}
                          {...inputProps}
                          style={{
                            width: '100%',
                            height: '100%',
                            borderRadius: '5px 0 0 5px',
                            border: 'none',
                            padding: '10px 15px',
                            fontFamily: 'Century Gothic',
                            fontSize: 14,
                            lineHeight: '140%',
                            letterSpacing: '0.2px',
                            color: lightTheme.palette.text.primary,
                            backgroundColor: '#FFFFFF',
                          }}
                        />
                        {InputProps?.endAdornment}
                      </Box>
                    )}
                  />
                </LocalizationProvider>
                {errorDate && (
                  <Typography className={'error-message'}>
                    {t('errorMessage.date')}
                  </Typography>
                )}
              </Grid>
              <Grid
                item
                xs={12}
                sm={6}
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  rowGap: '5px',
                }}
              >
                <Typography>
                  <b>{t(`contact.slots`)}</b>
                </Typography>
                {loadingSlots ? (
                  <Grid
                    item
                    xs={12}
                    sx={{
                      textAlign: 'center',
                    }}
                  >
                    <CircularProgress />
                  </Grid>
                ) : (
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center',
                      /*flexWrap: "wrap",*/
                      rowGap: '10px',

                      height: 200,
                      overflowY: 'scroll',
                      marginTop: '10px',

                      '&::-webkit-scrollbar': {
                        width: 10,
                      },

                      '&::-webkit-scrollbar-track': {
                        background: lightTheme.palette.primary.b5,
                      },

                      '&::-webkit-scrollbar-thumb': {
                        background: lightTheme.palette.primary.b3,
                      },

                      '&::-webkit-scrollbar-thumb:hover': {
                        background: lightTheme.palette.primary.b2,
                      },
                    }}
                  >
                    {slots.map((slot) => {
                      return (
                        <ButtonRadioInput
                          value={slot}
                          handleChange={handleChangeButtonRadio}
                          border={'1px solid #9E9E9E'}
                          width={'80%'}
                          spanHeight={'30px'}
                          isChecked={times === slot}
                        >
                          {slot.replace(/:/g, 'h').substr(0, 5)}
                        </ButtonRadioInput>
                      )
                    })}
                  </Box>
                )}
                {errorTime && (
                  <Typography className={'error-message'}>
                    {t('errorMessage.dateTime')}
                  </Typography>
                )}
              </Grid>
              {/* <Grid
                  item
                  xs={12}
                  sm={6}
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    rowGap: '5px',
                  }}
                >
                  <FormLabel className={'labelForm'}>Heure</FormLabel>
                  <TextField
                    type="time"
                    defaultValue="10:30"
                    onChange={changeTime}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    inputProps={{
                      step: 300, // 5 min
                    }}
                    style={{
                      width: '100%',
                      height: 45,
                      borderRadius: '5px 0 0 5px',
                      border: '1px solid #9E9E9E',
                      padding: '10px 15px',
                      fontFamily: 'Century Gothic',
                      fontSize: 14,
                      letterSpacing: '0.2px',
                      color: lightTheme.palette.text.primary,
                      backgroundColor: '#FFFFFF',
                    }}
                  />
                  <TimePicker
                    value={timeValue}
                    onChange={changeTime}
                    renderInput={({ inputRef, inputProps, InputProps }) => (
                      <Box
                        sx={{
                          display: 'flex',
                          alignItems: 'center',
                          width: '100%',
                          height: 45,
                          backgroundColor: lightTheme.palette.primary.b2,
                          borderRadius: '5px',

                          '& .css-1laqsz7-MuiInputAdornment-root': {
                            height: '100%',
                            width: '50px',
                            margin: 0,
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',

                            '& .css-1yq5fb3-MuiButtonBase-root-MuiIconButton-root':
                              {
                                margin: 0,
                                color: '#FFFFFF',
                              },
                          },
                        }}
                      >
                        <input
                          ref={inputRef}
                          {...inputProps}
                          style={{
                            width: '100%',
                            height: '100%',
                            borderRadius: '5px 0 0 5px',
                            border: '1px solid #9E9E9E',
                            padding: '10px 15px',
                            fontFamily: 'Century Gothic',
                            fontSize: 14,
                            lineHeight: '140%',
                            letterSpacing: '0.2px',
                            color: lightTheme.palette.text.primary,
                            backgroundColor: '#FFFFFF',
                          }}
                        />
                        {InputProps?.endAdornment}
                      </Box>
                    )}
                  />
                  {errorTime && (
                    <Typography className={'error-message'}>
                      Merci de renseigner une plage horraire.
                    </Typography>
                  )}
                </Grid> */}
              {/* </LocalizationProvider> */}
              <Grid
                item
                sm={12}
                sx={{ display: 'flex', flexDirection: 'column', rowGap: '5px' }}
              >
                <FormLabel className={'labelForm'}>
                  {t('box.videoconferenceLink')}
                </FormLabel>
              </Grid>
            </Grid>
          </BoxContainer>
          {/*<Box className={"wrapperButtons"} width={"100%"}>*/}
          <Box className={'blockButtons'}>
            {step === 2 && (
              <BorderButtonPurple
                displayIcon={'none'}
                handleClick={(e) => {
                  step === 1 ? onClose() : setStep(1)
                }}
              >
                {t('button.back')}
              </BorderButtonPurple>
            )}

            {step === 1 ? (
              <CustomButton
                displayIcon={'none'}
                handleClick={(e) => {
                  setStep(2)
                }}
              >
                <FontAwesomeIcon icon={faCalendarPlus} className="arrowLeft" />{' '}
                {t('button.rdvSuggest')}
              </CustomButton>
            ) : (
              <CustomButton
                displayIcon={'none'}
                handleClick={handleSubmit(onSubmit)}
                disabled={true}
              >
                {loading ? (
                  <CircularProgress size={20} color="inherit" />
                ) : (
                  t('button.sendProposal')
                )}
              </CustomButton>
            )}
            {!isAdmin && step === 1 && (
              <>
                {isFavorie ? (
                  <CustomButton
                    displayIcon={'none'}
                    handleClick={(e) => {
                      removeFromFavorie()
                    }}
                  >
                    <FontAwesomeIcon
                      icon={faHeartBroken}
                      className="arrowLeft"
                    />
                    {loading ? (
                      <CircularProgress size={20} color="inherit" />
                    ) : (
                      t('button.removeFavorite')
                    )}
                  </CustomButton>
                ) : (
                  <CustomButton
                    displayIcon={'none'}
                    handleClick={(e) => {
                      addToFavorie()
                    }}
                  >
                    <FontAwesomeIcon icon={faHeart} className="arrowLeft" />
                    {loading ? (
                      <CircularProgress size={20} color="inherit" />
                    ) : (
                      t('button.addFavorite')
                    )}
                  </CustomButton>
                )}
              </>
            )}
            {step === 1 && (
              <>
                <CustomButton
                  displayIcon={'none'}
                  handleClick={(e) => {
                    removeCandidatMatch()
                  }}
                >
                  <FontAwesomeIcon icon={faChainBroken} className="arrowLeft" />

                  {loadingMatch ? (
                    <CircularProgress size={20} color="inherit" />
                  ) : (
                    t('button.retirerCandidat')
                  )}
                </CustomButton>
              </>
            )}
          </Box>
        </ModalContainer>
      </Modal>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
        closeButton={false}
        transition={Slide}
        className={'toast'}
      />
    </>
  )
}

export default DetailCandidat
