import React, { useState } from 'react'
import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  ClickAwayListener,
  Container,
  Grid,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Paper,
  Popper,
  styled,
  Typography,
} from '@mui/material'
import lightTheme from '../../styles/theme/lightTheme'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faPen,
  faFilePlus,
  faCalendarPlus,
  faExclamationTriangle,
} from '@fortawesome/pro-regular-svg-icons'
import lock from '../../assets/images/lock.png'
import '../../styles/components/_box.scss'
import { CustomButton } from '../buttons'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router'
import {
  faClose,
  faInfoCircle,
  faTimesCircle,
} from '@fortawesome/pro-solid-svg-icons'
import { niveauCompetencesList } from '../../data'

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: '#FFFFFF',
  height: 435,
  borderRadius: 0,
  boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.05)',
  padding: '15px',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  rowGap: '20px',
}))

const ItemLong = styled(Paper)(({ theme }) => ({
  backgroundColor: '#FFFFFF',
  padding: '15px',
}))

const BannerCircle = styled(Box)(({ theme }) => ({
  backgroundColor: lightTheme.palette.secondary.main,
  height: 50,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  columnGap: '30px',

  '.firstCircle, .secondCircle, .thirdCircle, .fourthCircle, .fiveCircle': {
    width: 10,
    height: 10,
    backgroundColor: lightTheme.palette.secondary.main,
    border: '1px solid #FFFFFF',
    borderRadius: '50%',
  },
}))

const TitleBox = styled(Typography)(({ theme }) => ({
  fontFamily: 'Century Gothic Bold',
  fontSize: 14,
  lineHeight: '140%',
  letterSpacing: '0.2px',
  color: lightTheme.palette.text.primary,
  textTransform: 'uppercase',
}))

const TitleContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
}))

const IconContainer = styled(Box)(({ theme }) => ({
  width: 30,
  height: 30,
  border: `1px solid ${lightTheme.palette.primary.b2}`,
  borderRadius: '3px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  cursor: 'pointer',

  '&:hover': {
    borderWidth: '2px',
  },

  '&:active': {
    borderWidth: '1px',
  },
}))

const WrapperIcons = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  columnGap: '8px',
}))

const BannerSquare = styled(Box)(({ theme }) => ({
  backgroundColor: lightTheme.palette.warning.dark,
  height: 50,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  columnGap: '30px',

  '.firstSquare, .secondSquare, .thirdSquare, .fourthSquare, .fiveSquare': {
    width: 10,
    height: 10,
    backgroundColor: '#FFFFFF',
    /*border: "1px solid #FFFFFF",
    borderRadius: "50%"*/
  },
}))

const UserMasonryLayout = ({
  user,
  userLangues,
  profil,
  besoinClient,
  resumeClient,
  besoin,
  resume,
  rendezVous,
  offre,
  files,
  atelier,
  ged,
  relation,
  valeurs,
  competences,
  competencesAnnexes,
  softskils,
  type,
  displayValeurSection,
  displayCompetencesSection,
  displaySoftskilSection,
  handleOpenDoc,
  handleOpenBesoin,
  handleOpenResume,
  handleOpenCompetences,
  handleOpenCompetencesAnnexes,
  handleOpenValeurs,
  handleOpenSoftskils,
  handleOpenOffer,
  handleOpenAddEvent,
  loading,
}) => {
  const { t, i18n } = useTranslation()
  const navigate = useNavigate()
  const [anchorEl, setAnchorEl] = useState(null)
  const [anchorEl2, setAnchorEl2] = useState(null)
  const [anchorEl3, setAnchorEl3] = useState(null)
  const [openTitle, setOpenTitle] = useState(false)
  const [openBesoin, setOpenBesoin] = useState(false)
  const [openResume, setOpenResume] = useState(false)

  const handleClickTitle = (event) => {
    setAnchorEl(anchorEl ? null : event.currentTarget)
    setOpenTitle(!openTitle)
  }
  const handleClickBesoin = (event) => {
    setAnchorEl2(anchorEl2 ? null : event.currentTarget)
    setOpenBesoin(!openBesoin)
  }
  const handleClickResume = (event) => {
    setAnchorEl3(anchorEl3 ? null : event.currentTarget)
    setOpenResume(!openResume)
  }

  if (loading)
    return (
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          width: '100%',
          height: '100vh',
        }}
      >
        <CircularProgress />
      </Box>
    )
  return (
    <Container
      maxWidth={'lg'}
      sx={{ paddingTop: '25px', paddingBottom: '25px' }}
    >
      <Grid container spacing={4}>
        {(!besoin ||
          !resume ||
          userLangues.length === 0 ||
          competences.length === 0 ||
          /* competencesAnnexes.length < 6 || */
          softskils.length < 6 ||
          valeurs.length < 6 ||
          files.filter((obj) => obj.type === 'Cv' || obj.type === 'cv')
            .length === 0) && (
          <Grid item xs={12} order={{ sm: 1, lg: 1 }}>
            <Item
              sx={{
                height: '100%',
                border: '1px dashed #F76E11',
              }}
            >
              <Typography color={lightTheme.palette.error.main}>
                <FontAwesomeIcon icon={faExclamationTriangle} fontSize={16} />{' '}
                {t('profile.candidateErrorInfo')}
              </Typography>
              <ul>
                {userLangues.length === 0 && (
                  <li style={{ color: lightTheme.palette.error.main }}>
                    {t('filter.languages')}
                  </li>
                )}
                {!besoin && (
                  <li style={{ color: lightTheme.palette.error.main }}>
                    {t('profile.candidateSearch')}
                  </li>
                )}
                {files.filter((obj) => obj.type === 'Cv' || obj.type === 'cv')
                  .length === 0 && (
                  <li style={{ color: lightTheme.palette.error.main }}>
                    {t('profile.ged')} (CV)
                  </li>
                )}
                {!resume && (
                  <li style={{ color: lightTheme.palette.error.main }}>
                    {t('profile.candidateSummary')}
                  </li>
                )}
                {competences.length < 1 && (
                  <li style={{ color: lightTheme.palette.error.main }}>
                    {t('filter.competences')}
                  </li>
                )}
                {/* {competencesAnnexes.length < 6 && (
                  <li style={{ color: lightTheme.palette.error.main }}>
                    {t('filter.competencesAnnexes')} : {t('errorMessage.list')}
                  </li>
                )} */}
                {softskils.length < 6 && (
                  <li style={{ color: lightTheme.palette.error.main }}>
                    {t('filter.softskils')} : {t('errorMessage.list')}
                  </li>
                )}
                {valeurs.length < 6 && (
                  <li style={{ color: lightTheme.palette.error.main }}>
                    {t('filter.values')} : {t('errorMessage.list')}
                  </li>
                )}
              </ul>
            </Item>
          </Grid>
        )}
        <Grid item xs={12} sm={6} lg={4} order={{ sm: 1, lg: 1 }}>
          {type === 'entreprise' ? (
            <BannerCircle
              sx={{
                display: { xs: 'none', lg: 'flex' },
              }}
            >
              <TitleBox style={{ color: '#fff' }}>
                {t('profile.entreprise.title')}
              </TitleBox>
            </BannerCircle>
          ) : (
            <BannerSquare
              sx={{
                display: { xs: 'none', lg: 'flex' },
              }}
            >
              <TitleBox style={{ color: '#fff' }}>
                {t('profile.candidat.title')}{' '}
                <FontAwesomeIcon
                  style={{ cursor: 'pointer' }}
                  icon={openTitle ? faTimesCircle : faInfoCircle}
                  onClick={handleClickTitle}
                  fontSize={20}
                />
                <div>
                  <Popper
                    open={openTitle}
                    anchorEl={anchorEl}
                    placement="bottom"
                  >
                    <Paper
                      sx={{
                        padding: '10px',
                        width: '400px',
                        textAlign: 'justify',
                      }}
                    >
                      <Typography>{t('profile.candidateTitleInfo')}</Typography>
                    </Paper>
                  </Popper>
                </div>
              </TitleBox>
            </BannerSquare>
          )}

          <Item
            sx={{
              height: { sm: '100%', lg: 'calc(100% - 50px)' },
            }}
          >
            {profil}
          </Item>
        </Grid>
        {/* <Grid
          item
          xs={12}
          sm={6}
          lg={5}
          order={{ sm: 3, lg: 2 }}
          position={'relative'}
        >
          <Item
            sx={{
              height: '100%',
              display: 'flex',
              flexDirection: 'column',
              rowGap: '20px',
              justifyContent: 'space-between',
            }}
            className={offre ? '' : ''}
          >
            <TitleContainer>
              <TitleBox>{t('profile.purchaseHistory')}</TitleBox>
            </TitleContainer>

            <Box overflow={'auto'} width={'100%'} height={'100%'}>
              {offre}
            </Box>

            <CustomButton displayIcon={'none'} handleClick={handleOpenOffer}>
              {t('profile.addOffer')}
            </CustomButton>
          </Item>
        </Grid> */}
        <Grid item xs={12} sm={6} lg={4} order={{ sm: 2, lg: 4 }}>
          <Item
            sx={{
              height: '100%',
            }}
          >
            <TitleContainer>
              <TitleBox
                sx={{
                  color: !besoin ? lightTheme.palette.error.main : 'initial',
                }}
              >
                {t('profile.candidateSearch')}
                {!besoin && '*'}{' '}
                <FontAwesomeIcon
                  style={{ cursor: 'pointer' }}
                  icon={openBesoin ? faTimesCircle : faInfoCircle}
                  onClick={handleClickBesoin}
                  fontSize={20}
                />
                <div>
                  <Popper
                    open={openBesoin}
                    anchorEl={anchorEl2}
                    placement="bottom"
                  >
                    <Paper
                      sx={{
                        padding: '10px',
                        width: '400px',
                        textAlign: 'justify',
                      }}
                    >
                      <Typography>
                        {t('profile.candidateSearchInfo')}
                      </Typography>
                    </Paper>
                  </Popper>
                </div>
              </TitleBox>
              <WrapperIcons>
                <IconContainer onClick={handleOpenBesoin}>
                  <FontAwesomeIcon
                    icon={faPen}
                    fontSize={15}
                    color={lightTheme.palette.primary.b2}
                  />
                </IconContainer>
              </WrapperIcons>
            </TitleContainer>

            <Box>{besoinClient}</Box>
          </Item>
        </Grid>
        <Grid
          item
          xs={12}
          sm={6}
          lg={4}
          order={{ sm: 6, lg: 3 }}
          position={'relative'}
        >
          <Item
            sx={{
              height: '100%',
            }}
            className={''}
          >
            <TitleContainer>
              <TitleBox>{t('profile.rdv')}</TitleBox>
              <IconContainer onClick={handleOpenAddEvent}>
                <FontAwesomeIcon
                  icon={faCalendarPlus}
                  fontSize={15}
                  color={lightTheme.palette.primary.b2}
                />
              </IconContainer>
            </TitleContainer>

            <Box>{rendezVous}</Box>
          </Item>
          {/*  <img
            src={lock}
            alt={"Jobertech - not access"}
            style={{
              width: 150,
              height: 150,
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-40%, -45%)"
            }}
          /> */}
        </Grid>
        <Grid
          item
          xs={12}
          sm={6}
          lg={8}
          order={{ sm: 5, lg: 6 }}
          position={'relative'}
        >
          <Item
            sx={{
              height: { sm: 400, lg: 435 },
            }}
            className={ged ? '' : 'blurLayout'}
          >
            <TitleContainer>
              <TitleBox
                sx={{
                  color:
                    files.filter(
                      (obj) => obj.type === 'Cv' || obj.type === 'cv'
                    ).length === 0
                      ? lightTheme.palette.error.main
                      : 'initial',
                }}
              >
                {t('profile.ged')}
                {files.filter((obj) => obj.type === 'Cv' || obj.type === 'cv')
                  .length === 0 && '*'}
              </TitleBox>
              <WrapperIcons>
                <IconContainer onClick={handleOpenDoc}>
                  <FontAwesomeIcon
                    icon={faFilePlus}
                    fontSize={15}
                    color={lightTheme.palette.primary.b2}
                  />
                </IconContainer>
              </WrapperIcons>
            </TitleContainer>

            <Box overflow={'auto'} width={'100%'} height={'100%'}>
              {ged}
            </Box>
          </Item>
          {!ged && (
            <img
              src={lock}
              alt={'Jobertech - not access'}
              style={{
                width: 150,
                height: 150,
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-40%, -45%)',
              }}
            />
          )}
        </Grid>
        <Grid
          item
          xs={12}
          sm={6}
          lg={4}
          order={{ sm: 4, lg: 5 }}
          position={'relative'}
        >
          <Item
            sx={{
              height: { sm: 400, lg: 435 },
            }}
            className={''}
          >
            <TitleBox>{t('profile.linking')}</TitleBox>

            <Box>{relation}</Box>
          </Item>
          {/* <img
            src={lock}
            alt={"Jobertech - not access"}
            style={{
              width: 150,
              height: 150,
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-40%, -45%)"
            }}
          /> */}
        </Grid>

        <Grid item xs={12} sm={12} order={{ sm: 7 }} position={'relative'}>
          <Item
            sx={{
              height: '100%',
              display: 'flex',
              flexDirection: 'column',
              rowGap: '20px',
              justifyContent: 'space-between',
            }}
            className={atelier ? '' : ''}
          >
            <TitleContainer>
              <TitleBox>{t('profile.atelierHistory')}</TitleBox>
            </TitleContainer>

            <Box overflow={'auto'} width={'100%'} height={'100%'}>
              {atelier}
            </Box>

            <CustomButton
              displayIcon={'none'}
              handleClick={() => {
                navigate('/ateliers')
              }}
            >
              {t('profile.addAtelier')}
            </CustomButton>
          </Item>
        </Grid>
        <Grid item xs={12} sm={12} order={{ sm: 7 }}>
          <Item
            sx={{
              height: '100%',
            }}
          >
            <TitleContainer>
              <TitleBox
                sx={{
                  color: !resume ? lightTheme.palette.error.main : 'initial',
                }}
              >
                {t('profile.candidateSummary')}
                {!resume && '*'}{' '}
                <FontAwesomeIcon
                  style={{ cursor: 'pointer' }}
                  icon={openResume ? faTimesCircle : faInfoCircle}
                  onClick={handleClickResume}
                  fontSize={20}
                />
                <div>
                  <Popper
                    open={openResume}
                    anchorEl={anchorEl3}
                    placement="bottom"
                  >
                    <Paper
                      sx={{
                        padding: '10px',
                        width: '400px',
                        textAlign: 'justify',
                      }}
                    >
                      <Typography>
                        {t('profile.candidateSummaryInfo')}
                      </Typography>
                    </Paper>
                  </Popper>
                </div>
              </TitleBox>
              <WrapperIcons>
                <IconContainer onClick={handleOpenResume}>
                  <FontAwesomeIcon
                    icon={faPen}
                    fontSize={15}
                    color={lightTheme.palette.primary.b2}
                  />
                </IconContainer>
              </WrapperIcons>
            </TitleContainer>

            <Box>{resumeClient}</Box>
          </Item>
        </Grid>
        <Grid
          item
          xs={12}
          sm={12}
          order={{ sm: 7 }}
          display={displayCompetencesSection}
        >
          <ItemLong>
            <TitleContainer>
              <TitleBox
                sx={{
                  color:
                    competences.length < 1
                      ? lightTheme.palette.error.main
                      : 'initial',
                }}
              >
                {t('filter.competences')}
                {competences.length < 1 && '*'}
              </TitleBox>
              <WrapperIcons>
                <IconContainer onClick={handleOpenCompetences}>
                  <FontAwesomeIcon
                    icon={faPen}
                    fontSize={15}
                    color={lightTheme.palette.primary.b2}
                  />
                </IconContainer>
              </WrapperIcons>
            </TitleContainer>
            <Box>
              <ul className="list-values">
                {competences &&
                  competences.map((competence, i) => (
                    <li key={i}>
                      {competence.competence} :{' '}
                      <b>
                        {
                          niveauCompetencesList[competence.niveau - 1].titre[
                            i18n.language
                          ]
                        }
                      </b>
                    </li>
                  ))}
              </ul>
            </Box>
          </ItemLong>
        </Grid>
        <Grid
          item
          xs={12}
          sm={12}
          order={{ sm: 7 }}
          display={displayCompetencesSection}
        >
          <ItemLong>
            <TitleContainer>
              <TitleBox
              /* sx={{
                  color:
                    competencesAnnexes.length < 6
                      ? lightTheme.palette.error.main
                      : 'initial',
                }} */
              >
                {t('filter.competencesAnnexes')}
                {/* {competencesAnnexes.length < 6 && '*'} */}
              </TitleBox>
              <WrapperIcons>
                <IconContainer onClick={handleOpenCompetencesAnnexes}>
                  <FontAwesomeIcon
                    icon={faPen}
                    fontSize={15}
                    color={lightTheme.palette.primary.b2}
                  />
                </IconContainer>
              </WrapperIcons>
            </TitleContainer>
            <Box>
              <ul className="list-values">
                {competencesAnnexes &&
                  competencesAnnexes.map((competence, i) => (
                    <li key={i}>
                      {competence.competence} :{' '}
                      <b>
                        {
                          niveauCompetencesList[competence.niveau - 1].titre[
                            i18n.language
                          ]
                        }
                      </b>
                    </li>
                  ))}
              </ul>
            </Box>
          </ItemLong>
        </Grid>
        <Grid
          item
          xs={12}
          sm={12}
          order={{ sm: 8 }}
          display={displaySoftskilSection}
        >
          <ItemLong>
            <TitleContainer>
              <TitleBox
                sx={{
                  color:
                    softskils.length < 6
                      ? lightTheme.palette.error.main
                      : 'initial',
                }}
              >
                {t('filter.softskils')}
                {softskils.length < 6 && '*'}
              </TitleBox>
              <WrapperIcons>
                <IconContainer onClick={handleOpenSoftskils}>
                  <FontAwesomeIcon
                    icon={faPen}
                    fontSize={15}
                    color={lightTheme.palette.primary.b2}
                  />
                </IconContainer>
              </WrapperIcons>
            </TitleContainer>
            <Box>
              <ul className="list-values">
                {softskils &&
                  softskils.map((softskil, i) => <li key={i}>{softskil}</li>)}
              </ul>
            </Box>
          </ItemLong>
        </Grid>
        <Grid
          item
          xs={12}
          sm={12}
          order={{ sm: 9 }}
          display={displayValeurSection}
        >
          <ItemLong>
            <TitleContainer>
              <TitleBox
                sx={{
                  color:
                    valeurs.length < 6
                      ? lightTheme.palette.error.main
                      : 'initial',
                }}
              >
                {t('filter.values')}
                {valeurs.length < 6 && '*'}
              </TitleBox>
              <WrapperIcons>
                <IconContainer onClick={handleOpenValeurs}>
                  <FontAwesomeIcon
                    icon={faPen}
                    fontSize={15}
                    color={lightTheme.palette.primary.b2}
                  />
                </IconContainer>
              </WrapperIcons>
            </TitleContainer>
            <Box>
              <ul className="list-values">
                {valeurs &&
                  valeurs.map((valeur, i) => <li key={i}>{valeur}</li>)}
              </ul>
            </Box>
          </ItemLong>
        </Grid>
      </Grid>
    </Container>
  )
}

export default UserMasonryLayout
