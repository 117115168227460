import RegisterCard from '../../../components/cards/RegisterCard'
import React, { useEffect, useState } from 'react'
import {
  Autocomplete,
  Box,
  Chip,
  CircularProgress,
  FormControl,
  FormLabel,
  Grid,
  styled,
  TextField,
  Typography,
} from '@mui/material'
import { PasswordInputBasic } from '../../../components/inputs'
import { useForm } from 'react-hook-form'
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import TextareaInputClassic from '../../../components/inputs/TextareaInputClassic'
import '../../../styles/components/_grids.scss'
import lightTheme from '../../../styles/theme/lightTheme'
import { BorderButton, CustomButton } from '../../../components/buttons'
import { loadStripe } from '@stripe/stripe-js'
import Api from '../../../services/api.service'
import { handlingErrors } from '../../../utils'
import { passwordRegExp } from '../../../config'
import '../../../styles/components/_errors.scss'
import { toast } from 'react-toastify'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircleCheck } from '@fortawesome/pro-regular-svg-icons'
import StripeAppTest from '../../../components/Stripe/StripeAppTest'
import heartEnvelope from '../../../assets/icons/coolicon.png'
import { useNavigate } from 'react-router-dom'
import { VALEURS } from '../../../data'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { userLogout } from '../../../store/slices/userAuthReducer'

const schema = yup.object({
  password: yup
    .string()
    .matches(
      passwordRegExp,
      'Votre mot de passe doit comporter au moins 6 caractères et doit contenir au moins une lettre, un chiffre et un caractère spéciale (!@#$%^&*).'
    )
    .required('Merci de créer votre mot de passe.'),
  confirmPassword: yup
    .string()
    .required('Merci de ressaisir votre mot de passe.')
    .oneOf([yup.ref('password')], 'Le mot de passe ne correspond pas.'),
})

const InputContainer = styled(FormControl)(({ theme }) => ({
  rowGap: '5px',
}))

const CustomFormLabel = styled(FormLabel)(({ theme }) => ({
  fontFamily: 'Century Gothic Bold',
  fontSize: 10,
  lineHeight: '140%',
  letterSpacing: '0.2px',
  color: lightTheme.palette.text.primary,

  [lightTheme.breakpoints.up('md')]: {
    fontSize: 12,
  },

  [lightTheme.breakpoints.up('lg')]: {
    fontSize: 14,
  },
}))

const StepsEntreprise = ({ token }) => {
  const { t, i18n } = useTranslation()
  const [currentStep, setCurrentStep] = useState(0)
  const [loading, setLoading] = useState(false)
  const [snapShot, setSnapShot] = useState(null)
  const [id, setId] = useState(null)
  const [openCard, setOpenCard] = useState(false)
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const [valeurs, setValeurs] = useState([])
  const [password, setPassword] = useState('')
  const [errorLength, setErrorLength] = useState(false)
  const [errorNumber, setErrorNumber] = useState(false)
  const [errorSpecialCarter, setErrorSpecialCarter] = useState(false)

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({ resolver: yupResolver(schema) })

  useEffect(() => {
    const containsNumber = /\d/.test(password)
    const containsSpecialCharacter = /[!@#$%^&*]/.test(password)
    setErrorLength(password.length > 6)
    setErrorNumber(containsNumber)
    setErrorSpecialCarter(containsSpecialCharacter)
  }, [password])

  function onClose() {
    setCurrentStep(0)
  }

  function onSuccess() {
    toast.success('Félicitations ! Votre compte est confirmé.', {
      position: 'top-right',
      autoClose: 5000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: 'light',
      icon: ({ theme, type }) => (
        <FontAwesomeIcon
          icon={faCircleCheck}
          fontSize={25}
          color={lightTheme.palette.success.main}
        />
      ),
    })
    navigate('/auth/se-connecter')
  }

  function nextStep() {
    setCurrentStep(currentStep + 1)
  }

  /*function handleChange(event) {
    setFile(event.target.files[0])
  }*/

  /* const handleFileChange = (event) => {
    setFile(event.target.files[0])
  } */

  const submitFinishInscription = async (values) => {
    setLoading(true)

    const result = await Api.finalisationInscription.postInscription(token, {
      password: values.password,
      valeurs: valeurs,
    })

    const handling = handlingErrors(result)

    if (handling.status === 401) {
      console.log('erreur post')
    }

    if (handling.ok) {
      const res = handling.data
      setId(res.id)
      setSnapShot(res)
      setOpenCard(true)
      setLoading(false)
      console.log(
        res.entrepriseOffres.filter(
          (entrepriseOffre) => entrepriseOffre.status === 'payer'
        )
      )
      if (
        res.entrepriseOffres.filter(
          (entrepriseOffre) => entrepriseOffre.status === 'non-payer'
        ).length === 0
      ) {
        toast.success('Félicitations ! Votre compte est confirmé.', {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'light',
          icon: ({ theme, type }) => (
            <FontAwesomeIcon
              icon={faCircleCheck}
              fontSize={25}
              color={lightTheme.palette.success.main}
            />
          ),
        })
        navigate('/auth/se-connecter')
      }
    } else {
      //console.log('erreur post')
      setLoading(false)
    }
  }

  /*  useEffect(() => {
    console.log("sanpshot inscription", snapShot);
  }, [id]) */

  return (
    <>
      <RegisterCard>
        <form style={{ height: '100%', position: 'relative' }}>
          <Grid container spacing={2} className={'gridContainerRegister'}>
            {!openCard ? (
              <>
                <Grid item xs={12}>
                  <InputContainer fullWidth>
                    <CustomFormLabel>
                      Choisissez un mot de passe
                    </CustomFormLabel>
                    {/*<TextInputClassic
                      placeholder={"Mot de passe"}
                      width={"100%"}
                      register={{...register("password")}}
                      type={"password"}
                    />*/}

                    <PasswordInputBasic
                      placeholder={'Mot de passe'}
                      register={{ ...register('password') }}
                      onChange={(e) => setPassword(e.target.value)}
                    />

                    {errors.password && (
                      <Typography className={'error-message'}>
                        {errors.password.message}
                      </Typography>
                    )}
                    <div>
                      <ul style={{ fontSize: 12 }}>
                        <li
                          style={{
                            textDecoration: errorLength
                              ? 'line-through'
                              : 'none',
                          }}
                        >
                          Au moins 6 caractères
                        </li>
                        <li
                          style={{
                            textDecoration: errorNumber
                              ? 'line-through'
                              : 'none',
                          }}
                        >
                          Un chiffre
                        </li>
                        <li
                          style={{
                            textDecoration: errorSpecialCarter
                              ? 'line-through'
                              : 'none',
                          }}
                        >
                          Un caractère spéciale (!@#$%^&*)
                        </li>
                      </ul>
                    </div>
                  </InputContainer>
                </Grid>
                <Grid item xs={12}>
                  <InputContainer fullWidth>
                    <CustomFormLabel>
                      Ressaisissez le mot de passe
                    </CustomFormLabel>
                    {/*<TextInputClassic
                      placeholder={"Confirmer mot de passe"}
                      width={"100%"}
                      register={{...register("confirmPassword")}}
                      type={"password"}
                    />*/}

                    <PasswordInputBasic
                      placeholder={'Confirmer mot de passe'}
                      register={{ ...register('confirmPassword') }}
                    />

                    {errors.confirmPassword && (
                      <Typography className={'error-message'}>
                        {errors.confirmPassword.message}
                      </Typography>
                    )}
                  </InputContainer>
                </Grid>
                <Grid item xs={12}>
                  <InputContainer fullWidth>
                    <CustomFormLabel>
                      Quel sont les valeurs de l'entreprise ?
                    </CustomFormLabel>
                    <Autocomplete
                      sx={{ width: '100%', backgroundColor: '#fff' }}
                      multiple
                      defaultValue={valeurs}
                      options={[...VALEURS['fr'], ...VALEURS['en']]}
                      onChange={(event, newValue) => {
                        setValeurs(newValue)
                      }}
                      renderTags={(valeurs, getTagProps) =>
                        valeurs.map((valeur, index) => (
                          <Chip
                            variant="outlined"
                            label={valeur}
                            {...getTagProps({ index })}
                          />
                        ))
                      }
                      renderInput={(params) => (
                        <TextField {...params} placeholder={' '} />
                      )}
                    />
                  </InputContainer>
                </Grid>
              </>
            ) : (
              <>
                {currentStep === 0 && (
                  <>
                    <Grid item xs={12}>
                      <Typography
                        variant={'subtitle2'}
                        textTransform={'uppercase'}
                      >
                        Résumé de l'offre proposé :
                      </Typography>
                    </Grid>

                    <Grid item xs={12}>
                      <Typography variant={'h5'} textTransform={'uppercase'}>
                        {i18n.language === 'fr'
                          ? snapShot.entrepriseOffres.find(
                              (entrepriseOffre) =>
                                entrepriseOffre.status === 'non-payer'
                            ).offre.titre
                          : snapShot.entrepriseOffres.find(
                              (entrepriseOffre) =>
                                entrepriseOffre.status === 'non-payer'
                            ).offre.titreEn}
                      </Typography>
                      <Typography
                        variant={'subtitle2'}
                        textTransform={'uppercase'}
                      >
                        {
                          snapShot.entrepriseOffres.find(
                            (entrepriseOffre) =>
                              entrepriseOffre.status === 'non-payer'
                          ).offre.sousTitre
                        }
                      </Typography>
                    </Grid>

                    <Grid item xs={12}>
                      <Typography variant={'subtitle2'}>
                        {t('button.period')} :{' '}
                        {
                          snapShot.entrepriseOffres.find(
                            (entrepriseOffre) =>
                              entrepriseOffre.status === 'non-payer'
                          ).periode
                        }{' '}
                        {t('box.mounth')}
                      </Typography>
                      {/* <ul>
                          {
                            snapShot.entrepriseOffres.find(
                              (entrepriseOffre) =>
                                entrepriseOffre.status === 'non-payer'
                            ).periode
                          }
                          {''}
                          Mois
                      </ul> */}
                    </Grid>

                    <Grid item xs={12}>
                      <Typography variant={'subtitle2'}>
                        {t('profile.price')} :{' '}
                        {(
                          snapShot.entrepriseOffres.find(
                            (entrepriseOffre) =>
                              entrepriseOffre.status === 'non-payer'
                          ).prix *
                          JSON.parse(sessionStorage.getItem('currency'))
                            .currentRate
                        ).toFixed(2)}{' '}
                        {sessionStorage.getItem('currency')
                          ? JSON.parse(sessionStorage.getItem('currency'))
                              .devise
                          : 'CHF'}
                      </Typography>
                    </Grid>

                    <Grid item xs={12}>
                      <Typography variant={'subtitle2'}>
                        {t('profile.offerContent')} :
                      </Typography>
                      <ul>
                        {snapShot.entrepriseOffres
                          .find(
                            (entrepriseOffre) =>
                              entrepriseOffre.status === 'non-payer'
                          )
                          .offre.permissions.sort((a, b) => a.id - b.id)
                          .map((permission, index) => (
                            <li>
                              {i18n.language === 'fr'
                                ? permission.nom
                                : permission.nomEn}
                            </li>
                          ))}
                      </ul>
                    </Grid>
                  </>
                )}

                {/*{currentStep === 1 && (
                  <>
                    <Grid
                      item
                      xs={12}
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        rowGap: "50px",
                        position: "absolute",
                        top: "20px",
                        width: "calc(100% - 47px)",
                        height: "calc(100% - 47px)"
                      }}
                    >
                      <Typography variant={"subtitle2"} textTransform={"uppercase"}>Prix de l'offre "{snapShot.entrepriseOffres.find(entrepriseOffre => entrepriseOffre.status === "payer").offre.titre}" - {snapShot.entrepriseOffres.find(entrepriseOffre => entrepriseOffre.status === "payer").prix} €</Typography>
                      <Box>
                        <Stripe userId={snapShot.id} offerId={snapShot.entrepriseOffres.find(entrepriseOffre => entrepriseOffre.status === "payer").offre.id} offerPrice={snapShot.entrepriseOffres.find(entrepriseOffre => entrepriseOffre.status === "payer").prix}/>
                      </Box>
                    </Grid>
                  </>
                )}*/}
              </>
            )}

            <Box
              sx={{
                width: 'calc(100% - 16px)',
                display: 'flex',
                flexDirection: 'row',
                columnGap: '15px',
                justifyContent: 'flex-end',
                alignItems: 'end',
                marginLeft: '16px',
              }}
            >
              {currentStep > 0 && (
                <Box width={'50%'} zIndex={1}>
                  <BorderButton
                    type={'basic'}
                    displayIconRight={'none'}
                    handleClick={() => setCurrentStep(currentStep - 1)}
                  >
                    {t('contact.prevStep')}
                  </BorderButton>
                </Box>
              )}
              <Box width={'50%'}>
                {loading ? (
                  <CircularProgress />
                ) : (
                  <>
                    {!openCard ? (
                      <CustomButton
                        handleClick={handleSubmit(submitFinishInscription)}
                      >
                        {t('contact.nextStep')}
                      </CustomButton>
                    ) : currentStep === 0 ? (
                      <CustomButton handleClick={nextStep}>
                        {t('successMessage.proceedPayment')}
                      </CustomButton>
                    ) : (
                      <></>
                    )}
                  </>
                )}
              </Box>
            </Box>
          </Grid>
        </form>

        {currentStep === 1 && (
          <>
            <Grid
              item
              xs={12}
              sx={{
                display: 'flex',
                flexDirection: 'column',
                rowGap: '50px',
                position: 'absolute',
                top: '20px',
                width: 'calc(100% - 47px)',
                height: 'calc(100% - 47px)',
              }}
            >
              <Typography variant={'subtitle2'} textTransform={'uppercase'}>
                {t('box.title.paimentOffer')} "
                {i18n.language === 'fr'
                  ? snapShot.entrepriseOffres.find(
                      (entrepriseOffre) =>
                        entrepriseOffre.status === 'non-payer'
                    ).offre.titre
                  : snapShot.entrepriseOffres.find(
                      (entrepriseOffre) =>
                        entrepriseOffre.status === 'non-payer'
                    ).offre.titreEn}
                " -{' '}
                {(
                  snapShot.entrepriseOffres.find(
                    (entrepriseOffre) => entrepriseOffre.status === 'non-payer'
                  ).prix *
                  JSON.parse(sessionStorage.getItem('currency')).currentRate
                ).toFixed(2)}
                {''}
                {sessionStorage.getItem('currency')
                  ? JSON.parse(sessionStorage.getItem('currency')).devise
                  : 'CHF'}
              </Typography>
              <Box>
                <StripeAppTest
                  userId={snapShot.id}
                  type={'offerEntreprise'}
                  offreId={
                    snapShot.entrepriseOffres.find(
                      (entrepriseOffre) =>
                        entrepriseOffre.status === 'non-payer'
                    ).id
                  }
                  offrePeriode={
                    snapShot.entrepriseOffres.find(
                      (entrepriseOffre) =>
                        entrepriseOffre.status === 'non-payer'
                    ).periode
                  }
                  offrePrice={(
                    snapShot.entrepriseOffres.find(
                      (entrepriseOffre) =>
                        entrepriseOffre.status === 'non-payer'
                    ).prix *
                    JSON.parse(sessionStorage.getItem('currency')).currentRate
                  ).toFixed(2)}
                  onSuccess={onSuccess}
                  onClose={onClose}
                />
              </Box>
            </Grid>
          </>
        )}

        {currentStep === 2 && (
          <Grid item xs={12}>
            <Box>
              <img src={heartEnvelope} alt={'Jobertech - Welcome'} />
            </Box>
            <Typography variant={'subtitle2'} textTransform={'uppercase'}>
              {t('successMessage.offerPayementOk')}
            </Typography>
            <Box sx={{ height: '100%' }}>
              <Typography>
                {t('successMessage.offerTextPayementOk')}{' '}
                <b>
                  {' '}
                  {i18n.language === 'fr'
                    ? snapShot.entrepriseOffres.find(
                        (entrepriseOffre) =>
                          entrepriseOffre.status === 'non-payer'
                      ).offre.titre
                    : snapShot.entrepriseOffres.find(
                        (entrepriseOffre) =>
                          entrepriseOffre.status === 'non-payer'
                      ).offre.titreEn}
                </b>
                .
              </Typography>
            </Box>
            <Box
              sx={{
                width: 'calc(100% - 10px)',
                display: 'flex',
                flexDirection: 'row',
                columnGap: '15px',
              }}
            >
              <Box width={'100%'} zIndex={1}>
                <BorderButton
                  type={'basic'}
                  displayIconLeft={'none'}
                  handleClick={() => {
                    dispatch(userLogout())
                    navigate('/auth/se-connecter')
                  }}
                >
                  {t('button.connect')}
                </BorderButton>
              </Box>
            </Box>
          </Grid>
        )}
      </RegisterCard>
    </>
  )
}

export default StepsEntreprise
