import React, { useEffect, useState } from 'react'
/*import {useTranslation} from "react-i18next";*/
import BannerPictureHome from './components/BannerPictureHome'
import AboutHome from './components/AboutHome'
import OffersHome from './components/OffersHome'
import CommunityHome from './components/CommunityHome'
import SpecialityHome from './components/SpecialityHome'
import StrategyHome from './components/StrategyHome'
import CommentsHome from './components/CommentsHome'
import PartnershipsHome from './components/PartnershipsHome'
import {
  Box,
  CircularProgress,
  Grid,
  Modal,
  styled,
  Typography,
} from '@mui/material'
import Api from '../../../../services/api.service'
import { handlingErrors } from '../../../../utils'
import { useTranslation } from 'react-i18next'
import HowSection from './components/HowSection'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faClose } from '@fortawesome/pro-solid-svg-icons'
import { Markup } from 'interweave'
import { useNavigate } from 'react-router-dom'
/*import ContentsHome from "./components/ContentsHome";
import WorkshopsHome from "./components/WorkshopsHome";*/

const ModalContainer = styled(Box)(({ theme }) => ({
  width: 'calc(100% - 50px)',
  /* minHeight: 250, */
  backgroundColor: '#FFFFFF',
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  padding: '30px',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  rowGap: '20px',
  borderRadius: 10,

  '&:focus-visible': {
    outline: 'none',
  },

  [theme.breakpoints.up('sm')]: {
    width: 700,
    /*height: 500,*/
    height: 'auto',
    rowGap: '35px',
  },
}))

const BoxContainer = styled(Box)(({ theme }) => ({
  width: '100%',
  /* minHeight: 250, */

  [theme.breakpoints.up('sm')]: {
    /*height: 300,*/
    height: 'auto',
  },
}))

export default function Home() {
  const { i18n } = useTranslation()
  const navigate = useNavigate()
  const [homeData, setHomeData] = useState([])
  const [popupData, setPopupData] = useState()
  const [loading, setLoading] = useState(false)
  useEffect(() => {
    getHomeData(i18n.language)
  }, [i18n.language])

  const getHomeData = async (lang) => {
    setLoading(true)

    try {
      const result = await Api.siteVitrine.listWithFilter({
        lang: lang,
      })

      const res = handlingErrors(result)

      if (res.status === 401) {
        console.log('erreur')
      }

      if (res.ok) {
        setHomeData(res.data)
        if (
          res.data.filter((data) => data.section === 'popup')[0]?.status ===
          'active'
        ) {
          setPopupData(res.data.filter((data) => data.section === 'popup')[0])
          handleOpen()
        }
      }
      setLoading(false)
    } catch (error) {
      console.log(error)
    }
  }

  const [open, setOpen] = useState(false)
  const handleOpen = () => setOpen(true)
  const handleClose = () => setOpen(false)
  const handleClickPopup = () => navigate(popupData?.sousTitre)

  if (loading) {
    return (
      <main style={{ marginTop: '80px' }}>
        <CircularProgress />
      </main>
    )
  }

  return (
    <main>
      <BannerPictureHome
        accueil={homeData?.filter((data) => data.section === 'accueil')}
      />
      <SpecialityHome
        nosSpecialitesCandidats={homeData?.filter(
          (data) => data.section === 'nos-specialites-candidats'
        )}
        nosSpecialitesEntreprises={homeData?.filter(
          (data) => data.section === 'nos-specialites-entreprises'
        )}
      />
      <StrategyHome
        candidat={homeData?.filter((data) => data.section === 'candidat')}
        entreprises={homeData?.filter((data) => data.section === 'entreprises')}
      />
      <HowSection />
      <AboutHome
        apropos={homeData?.filter((data) => data.section === 'apropos')}
        about={homeData?.filter((data) => data.section === 'en-savoir-plus')}
      />
      <OffersHome
        offresCandidat={homeData?.filter(
          (data) => data.section === 'offres-candidat'
        )}
        offresEntreprises={homeData?.filter(
          (data) => data.section === 'offres-entreprises'
        )}
      />
      <CommunityHome
        commentRejoindre={homeData?.filter(
          (data) => data.section === 'comment-rejoindre'
        )}
      />
      <CommentsHome />
      <PartnershipsHome />
      {/*<ContentsHome/>*/}
      {/*<WorkshopsHome/>*/}
      {/*<div style={{height: "100px"}}/>*/}

      {popupData?.status === 'active' && (
        <Modal
          open={open}
          onClose={handleClose}
          sx={{
            '& .MuiBackdrop-root': {
              backgroundColor: 'rgba(51, 51, 51, 0.65)',
            },
          }}
        >
          <ModalContainer
            onClick={popupData?.sousTitre ? handleClickPopup : null}
            sx={{ cursor: popupData?.sousTitre ? 'pointer' : 'default' }}
          >
            <Typography variant={'subtitle2'} textTransform={'uppercase'}>
              {popupData?.titre}
              {/* <Box
                sx={{
                  position: 'absolute',
                  right: '10px',
                  top: '10px',
                  cursor: 'pointer',
                }}
              >
                <FontAwesomeIcon
                  icon={faClose}
                  fontSize={25}
                  onClick={handleClose}
                />
              </Box> */}
            </Typography>
            <BoxContainer>
              <Grid
                container
                spacing={2}
                sx={{
                  display: 'flex',
                  flexDirection: { xs: 'column', sm: 'row' },
                }}
              >
                <Grid
                  item
                  xs={12}
                  sm={12}
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    rowGap: '5px',
                  }}
                >
                  <img src={popupData?.photos[0]?.lien} />
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    rowGap: '5px',
                    textAlign: 'center',
                  }}
                >
                  <Markup
                    sx={{ textAlign: 'center' }}
                    content={popupData?.detail}
                  />
                </Grid>
              </Grid>
            </BoxContainer>
          </ModalContainer>
        </Modal>
      )}
    </main>
  )
}
