import React, { useState } from 'react'
import {
  Paper,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  styled,
  Box,
  Typography,
  CircularProgress,
} from '@mui/material'
import lightTheme from '../../styles/theme/lightTheme'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEye, faTrashCan } from '@fortawesome/pro-regular-svg-icons'
import { useSelector } from 'react-redux'
import moment from 'moment-timezone'
import { useTranslation } from 'react-i18next'

const HeaderTab = styled(TableHead)(({ theme }) => ({
  height: 60,
  backgroundColor: lightTheme.palette.primary.b6,
}))

const TitleTab = styled(TableCell)(({ theme }) => ({
  fontFamily: 'Century Gothic Bold',
  fontSize: 10,
  lineHeight: '140%',
  letterSpacing: '0.2px',
  color: lightTheme.palette.text.primary,
  textTransform: 'uppercase',

  [theme.breakpoints.up('sm')]: {
    fontSize: 12,
  },
  [theme.breakpoints.up('lg')]: {
    fontSize: 14,
  },
}))

const BodyTab = styled(TableCell)(({ theme, type }) => ({
  borderBottom:
    type === 'entreprise'
      ? `1px solid ${lightTheme.palette.secondary.main}`
      : type === 'candidat'
      ? `1px solid ${lightTheme.palette.warning.dark}`
      : `1px solid ${lightTheme.palette.secondary.main}`,
}))

const BlockTabCircle = styled(TableCell)(({ theme }) => ({
  width: 25,
  /*height: 61,*/
  height: '100%',
  backgroundColor: lightTheme.palette.secondary.main,
  padding: 0,
  margin: 0,
  borderBottom: `1px solid ${lightTheme.palette.secondary.main}`,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  rowGap: '5px',
  position: 'absolute',

  '.firstCircle, .secondCircle, .thirdCircle, .fourthCircle, .fiveCircle': {
    width: 5,
    height: 5,
    border: '1px solid #FFFFFF',
    borderRadius: '50%',
  },
}))

const BlockTabSquare = styled(TableCell)(({ theme }) => ({
  width: 25,
  /*height: 61,*/
  height: '100%',
  backgroundColor: lightTheme.palette.warning.dark,
  padding: 0,
  margin: 0,
  borderBottom: `1px solid ${lightTheme.palette.warning.dark}`,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  rowGap: '5px',
  position: 'absolute',

  '.firstSquare, .secondSquare, .thirdSquare, .fourthSquare, .fiveSquare': {
    width: 5,
    height: 5,
    backgroundColor: '#FFFFFF',
  },
}))

const BlockTab = styled(TableCell)(({ theme, type }) => ({
  width: 15,
  /*height: 60,*/
  height: '100%',
  padding: 0,
  backgroundColor:
    type === 'entreprise'
      ? lightTheme.palette.secondary.main
      : type === 'candidat'
      ? lightTheme.palette.warning.dark
      : lightTheme.palette.secondary.main,
  borderBottom:
    type === 'entreprise'
      ? `1px solid ${lightTheme.palette.secondary.main}`
      : type === 'candidat'
      ? `1px solid ${lightTheme.palette.warning.dark}`
      : `1px solid ${lightTheme.palette.secondary.main}`,
}))

const ButtonIcon = styled(Box)(({ theme }) => ({
  width: 40,
  height: 40,
  border: `1px solid ${lightTheme.palette.primary.b2}`,
  borderRadius: '3px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  cursor: 'pointer',
  margin: '0 5px',
  float: 'left',
  '&:hover': {
    border: `2px solid ${lightTheme.palette.primary.b2}`,
  },
  '&:active': {
    border: `1px solid ${lightTheme.palette.primary.b2}`,
  },
}))

const DeleteIcon = styled(Box)(({ theme }) => ({
  width: 40,
  height: 40,
  border: `1px solid ${lightTheme.palette.error.dark}`,
  borderRadius: '3px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  cursor: 'pointer',
  margin: '0 5px',
  float: 'left',
  '&:hover': {
    border: `2px solid ${lightTheme.palette.error.dark}`,
  },
  '&:active': {
    border: `1px solid ${lightTheme.palette.primary.b2}`,
  },
}))

const EmptyText = styled(Typography)(({ theme }) => ({
  fontFamily: 'Century Gothic',
  fontSize: 14,
  lineHeight: '140%',
  color: lightTheme.palette.grey.main,
  textAlign: 'center',
  fontStyle: 'italic',
  padding: '15px 0',
  background: 'rgba(0, 0, 0, 0.04)',
}))

const ListTableAtelier = ({
  type,
  items,
  setId,
  loading,
  handleClick,
  handleRemove,
  listeMatching = false,
  matchingPost = '',
  isAdmin = false,
}) => {
  const { t, i18n } = useTranslation()
  const userData = useSelector((state) => state.userAuth.user)
  if (loading)
    return (
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          width: '100%',
          height: '40vh',
        }}
      >
        <CircularProgress />
      </Box>
    )

  return (
    <>
      <TableContainer
        component={Paper}
        sx={{
          boxShadow: 'none',
          borderRadius: 0,
        }}
      >
        <Table>
          <HeaderTab>
            <TableRow>
              <TableCell>ID</TableCell>
              <TableCell>{t('profile.date')}</TableCell>
              <TableCell>{t('header.atelier')}</TableCell>
              <TableCell>{t('profile.price')}</TableCell>
              <TableCell>Nombre d'inscrits</TableCell>

              <TableCell />
              <TableCell width={15} sx={{ padding: 0 }} />
            </TableRow>
          </HeaderTab>
          <TableBody>
            {items?.length > 0 && (
              <>
                {items.map((item, index) => (
                  <TableRow key={index} sx={{ position: 'relative' }}>
                    <BodyTab>{item.id}</BodyTab>
                    <BodyTab>
                      {moment(item.date)
                        .tz('Europe/Paris')
                        .format('DD/MM/YYYY HH:mm')}
                    </BodyTab>

                    <BodyTab>{item.titre}</BodyTab>
                    <BodyTab>{(item.prix * JSON.parse(sessionStorage.getItem("currency")).currentRate).toFixed(2)} {(sessionStorage.getItem("currency")) ? JSON.parse(sessionStorage.getItem("currency")).devise : 'CHF'}</BodyTab>
                    <BodyTab>{item.userAteliers.length}</BodyTab>
                    <BodyTab
                      type={type}
                      sx={{
                        padding: '10px',
                        width: listeMatching ? 'auto' : '130px',
                      }}
                    >
                      <ButtonIcon
                        onClick={() => {
                          setId(item.id)
                          /*handleClick(`${item.nom} ${item.prenom}`);*/
                          handleClick(`${item.id}`, item)
                        }}
                      >
                        <FontAwesomeIcon
                          icon={faEye}
                          color={lightTheme.palette.primary.b2}
                          fontSize={16}
                        />
                      </ButtonIcon>
                    </BodyTab>
                    <BlockTab type={type} />
                  </TableRow>
                ))}
              </>
            )}
          </TableBody>
        </Table>
        {!items?.length > 0 && <EmptyText>pas d'Évènements ici...</EmptyText>}
      </TableContainer>

      {/*<AppPagination setProducts={(p) => setProducts(p)}/>*/}
    </>
  )
}

export default ListTableAtelier
