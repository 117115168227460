import React, { useEffect, useState } from 'react'
import {
  Box,
  CardContent,
  CircularProgress,
  Grid,
  styled,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faCircleExclamation,
  faArrowLeft,
} from '@fortawesome/pro-regular-svg-icons'
import lightTheme from '../../../../styles/theme/lightTheme'
import Api from '../../../../services/api.service'
import { handlingErrors } from '../../../../utils'
import { Slide, toast, ToastContainer } from 'react-toastify'
import { useNavigate, useParams } from 'react-router-dom'
import Button from '@mui/material/Button'
import { useTranslation } from 'react-i18next'
import { Markup } from 'interweave'
import { Container } from '@mui/system'
import moment from 'moment-timezone'
import { ROLE_CANDIDAT, ROLE_ENTREPRISE } from '../../../../data'

const GridItem = styled(Grid)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  rowGap: '20px',
}))

const HeaderTab = styled(TableHead)(({ theme }) => ({
  height: 60,
  backgroundColor: lightTheme.palette.primary.b6,
}))

const BodyTab = styled(TableCell)(({ theme, type }) => ({
  borderBottom:
    type === ROLE_ENTREPRISE
      ? `1px solid ${lightTheme.palette.secondary.main}`
      : type === ROLE_CANDIDAT
      ? `1px solid ${lightTheme.palette.warning.dark}`
      : `1px solid ${lightTheme.palette.secondary.main}`,
}))


const BlockTab = styled(TableCell)(({ theme, type }) => ({
  width: 15,
  /*height: 60,*/
  height: '100%',
  padding: 0,
  backgroundColor:
    type === ROLE_ENTREPRISE
      ? lightTheme.palette.secondary.main
      : type === ROLE_CANDIDAT
      ? lightTheme.palette.warning.dark
      : lightTheme.palette.secondary.main,
  borderBottom:
    type === ROLE_ENTREPRISE
      ? `1px solid ${lightTheme.palette.secondary.main}`
      : type === ROLE_CANDIDAT
      ? `1px solid ${lightTheme.palette.warning.dark}`
      : `1px solid ${lightTheme.palette.secondary.main}`,
}))

const AtelierDetail = () => {
  const { t, i18n } = useTranslation()
  const navigate = useNavigate()
  const [loading, setLoading] = useState(true)
  const [snapShot, setSnapShot] = useState(null)

  const params = useParams()

  const fetchData = async () => {
    try {
      let result = await Api.siteVitrine.getAtelier(params.atelierId)

      const handling = handlingErrors(result)

      if (handling.status === 401) {
        toast.error('Votre session à expiré, merci de vous reconnecter.', {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'light',
          icon: ({ theme, type }) => (
            <FontAwesomeIcon
              icon={faCircleExclamation}
              fontSize={25}
              color={lightTheme.palette.error.main}
            />
          ),
        })
        window.scrollTo(0, 0)
      }

      if (handling.ok) {
        const res = handling.data
        setSnapShot(res)

        setLoading(false)
      } else {
        setLoading(false)
        window.scrollTo(0, 0)
      }
    } catch (error) {
      setLoading(false)
      toast.error(
        "Quelque chose s'est mal passé ! Merci de réessayer plus tard.",
        {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'light',
          icon: ({ theme, type }) => (
            <FontAwesomeIcon
              icon={faCircleExclamation}
              fontSize={25}
              color={lightTheme.palette.error.main}
            />
          ),
        }
      )
    }
  }
  useEffect(() => {
    if (params.atelierId) fetchData()
  }, [params.atelierId])

  /*useEffect(() => {
    const DateFormat = format(new Date(dateValue), "dd/MM/yyyy");
    setDateRdv(DateFormat);
  })*/

  if (loading)
    return (
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          width: '100%',
          height: '100%',
        }}
      >
        <CircularProgress />
      </Box>
    )

  return (
    <div style={{ width: '100%' }}>
      <Button
        sx={{
          margin: '10px',
        }}
        onClick={() => navigate('/admin/gestion-contenus/ateliers')}
      >
        <FontAwesomeIcon
          icon={faArrowLeft}
          fontSize={15}
          color={lightTheme.palette.primary.b2}
        />{' '}
        <Typography
          variant={'body2'}
          color={lightTheme.palette.primary.b2}
          textAlign={'right'}
          marginLeft={1}
        >
          {'Retour'}
        </Typography>
      </Button>

      <Box
        className={'BoxContainer'}
        sx={{
          padding: '20px 25px',
          display: 'flex',
          flexDirection: 'column',
          rowGap: { xs: '30px', sm: '55px', lg: '70px' },
        }}
      >
        {/* <TitleSection>Détails et informations sur l'événement</TitleSection> */}
        <Grid container spacing={2} className={'gridContainer'}>
          <Grid item xs={12}>
            <CardContent>
              <Typography
                component={'h3'}
                sx={{
                  textAlign: 'center',
                  fontSize: { xs: 12, sm: 16, lg: 24 },
                  fontFamily: 'Century Gothic Bold',
                  color: '#333333',
                }}
              >
                {/* {snapShot.id} --  */}
                {snapShot.titre}
              </Typography>
              <br></br>
              <Typography
                component={'h1'}
                sx={{
                  textAlign: 'center',
                  fontSize: { sm: 14, lg: 18 },
                  fontFamily: 'Century Gothic Bold',
                  color: lightTheme.palette.text.primary,
                  lineHeight: '140%',
                }}
              >
                {/* {snapShot.id} --  */}
                {snapShot.sousTitre}
              </Typography>

              <Typography
                sx={{
                  textAlign: 'justify',
                  fontWeight: 'bold',
                  fontSize: { sm: 12, lg: 14 },
                  marginTop: 3,
                }}
              >
                <Markup content={snapShot.apropos} />
              </Typography>
            </CardContent>
          </Grid>
          <Grid container>
            <GridItem
              xs={6}
              sm={6}
              md={4}
              lg={3}
              alignItems={'center'}
              order={1}
            >
              <Typography
                sx={{
                  width: 'fit-content',
                  borderRadius: '100px',
                  background: '#fff',
                  zIndex: '1',
                  padding: '10px 20px',
                  fontSize: {
                    xs: '10px !important',
                    sm: '12px !important',
                    lg: '14px !important',
                  },
                  fontFamily: 'Century Gothic',
                  color: '#000',
                  float: 'left',
                  width: '90%',
                  marginBottom: { xs: '20px', sm: '20px', md: '20px', lg: 0 },
                  textAlign: 'right',
                }}
              >
                <Typography
                  sx={{
                    width: 'fit-content',
                    zIndex: '1',
                    fontWeight: '400',
                    fontFamily: 'Century Gothic Bold',
                    color: '#A4ADFF',
                    float: 'left',
                    marginRight: '10px',
                    textTransform: 'uppercase',
                  }}
                >
                  {t('profile.date')}
                </Typography>
                {moment(snapShot.date).tz('Europe/Paris').format('DD/MM/YYYY')}
              </Typography>
            </GridItem>
            <GridItem
              xs={6}
              sm={6}
              md={4}
              lg={2}
              alignItems={'center'}
              order={2}
            >
              <Typography
                sx={{
                  width: 'fit-content',
                  borderRadius: '100px',
                  background: '#fff',
                  zIndex: '1',
                  padding: '10px 20px',
                  fontSize: {
                    xs: '10px !important',
                    sm: '12px !important',
                    lg: '14px !important',
                  },
                  fontFamily: 'Century Gothic',
                  color: '#000',
                  float: 'left',
                  width: '90%',
                  marginBottom: { xs: '20px', sm: '20px', md: '20px', lg: 0 },
                  textAlign: 'right',
                }}
              >
                <Typography
                  sx={{
                    width: 'fit-content',
                    zIndex: '1',
                    fontWeight: '400',
                    fontFamily: 'Century Gothic Bold',
                    color: '#A4ADFF',
                    float: 'left',
                    marginRight: '10px',
                    textTransform: 'uppercase',
                  }}
                >
                  {t('profile.hour')}
                </Typography>
                {moment(snapShot.date).tz('Europe/Paris').format('HH:mm')}
              </Typography>
            </GridItem>
            <GridItem
              xs={6}
              sm={6}
              md={4}
              lg={2}
              alignItems={'center'}
              order={3}
            >
              <Typography
                sx={{
                  width: 'fit-content',
                  borderRadius: '100px',
                  background: '#fff',
                  zIndex: '1',
                  padding: '10px 20px',
                  fontSize: {
                    xs: '10px !important',
                    sm: '12px !important',
                    lg: '14px !important',
                  },
                  fontFamily: 'Century Gothic',
                  color: '#000',
                  float: 'left',
                  width: '90%',
                  marginBottom: { xs: '20px', sm: '20px', md: '20px', lg: 0 },
                  textAlign: 'right',
                }}
              >
                <Typography
                  sx={{
                    width: 'fit-content',
                    zIndex: '1',
                    fontWeight: '400',
                    fontFamily: 'Century Gothic Bold',
                    color: '#A4ADFF',
                    float: 'left',
                    marginRight: '10px',
                    textTransform: 'uppercase',
                  }}
                >
                  {t('profile.place')}
                </Typography>
                {snapShot.lieu}
              </Typography>
            </GridItem>
            <GridItem
              xs={12}
              sm={12}
              md={6}
              lg={3}
              alignItems={'center'}
              order={{ xs: 5, md: 4 }}
            >
              <Typography
                sx={{
                  width: 'fit-content',
                  borderRadius: '100px',
                  background: '#fff',
                  zIndex: '1',
                  padding: '10px 20px',
                  fontSize: {
                    xs: '10px !important',
                    sm: '12px !important',
                    lg: '14px !important',
                  },
                  fontFamily: 'Century Gothic',
                  color: '#000',
                  float: 'left',
                  width: '90%',
                  marginBottom: { xs: '20px', sm: '20px', md: '20px', lg: 0 },
                  textAlign: 'right',
                }}
              >
                <Typography
                  sx={{
                    width: 'fit-content',
                    zIndex: '1',
                    fontWeight: '400',
                    fontFamily: 'Century Gothic Bold',
                    color: '#A4ADFF',
                    float: 'left',
                    marginRight: '10px',
                    textTransform: 'uppercase',
                  }}
                >
                  {t('profile.language')}
                </Typography>
                {snapShot.langues &&
                  Object.values(snapShot.langues).join(' / ')}
              </Typography>
            </GridItem>
            <GridItem
              xs={6}
              sm={6}
              md={6}
              lg={2}
              alignItems={'center'}
              order={{ xs: 4, md: 5 }}
            >
              <Typography
                sx={{
                  width: 'fit-content',
                  borderRadius: '100px',
                  background: '#fff',
                  zIndex: '1',
                  padding: '10px 20px',
                  fontSize: {
                    xs: '10px !important',
                    sm: '12px !important',
                    lg: '14px !important',
                  },
                  fontFamily: 'Century Gothic',
                  color: '#000',
                  float: 'left',
                  width: '90%',
                  marginBottom: { xs: '20px', sm: '20px', md: '20px', lg: 0 },
                  textAlign: 'right',
                }}
              >
                <Typography
                  sx={{
                    width: 'fit-content',
                    zIndex: '1',
                    fontWeight: '400',
                    fontFamily: 'Century Gothic Bold',
                    color: '#A4ADFF',
                    float: 'left',
                    marginRight: '10px',
                    textTransform: 'uppercase',
                  }}
                >
                  {t('profile.price')}
                </Typography>
                {(
                  snapShot.prix *
                  JSON.parse(sessionStorage.getItem('currency')).currentRate
                ).toFixed(2)}{' '}
                {JSON.parse(sessionStorage.getItem('currency')).devise}
              </Typography>
            </GridItem>
          </Grid>
          {/* Block Partisipants */}
          {snapShot?.intervenants?.length > 0 && (
            <Container
              maxWidth={'lg'}
              sx={{
                position: 'relative',
                display: 'flex',
                justifyContent: 'center',
                marginBottom: '50px',
                marginTop: '50px',
                /* width: { xs: '100vw' }, */
                backgroundColor: '#E3E6FF',
                padding: '0 !important',
                minHeight: '80px',
                borderRadius: '85px',
                padding: {
                  xs: '0 50px',
                  sm: '0 50px',
                  md: '0 !important',
                  lg: '0 !important',
                },
              }}
            >
              <Grid container>
                <GridItem
                  item
                  xs={6}
                  sm={0}
                  md={2}
                  lg={3}
                  alignItems={'center'}
                  order={1}
                  sx={{
                    width: '170px !important',
                    height: '170px !important',
                    maxWidth: '170px !important',
                    backgroundColor: '#A4ADFF',
                    borderRadius: '85px',
                    padding: '40px 0',
                    marginRight: '50px',
                    paddingLeft: '0 !important',
                    display: {
                      xs: 'none',
                      sm: 'none',
                      md: 'flex',
                      lg: 'flex',
                    },
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: {
                        xs: '10px !important',
                        sm: '12px !important',
                        lg: '14px !important',
                      },
                      fontFamily: 'Century Gothic',
                      color: '#fff',
                      marginBottom: {
                        xs: '20px',
                        sm: '20px',
                        md: '20px',
                        lg: 0,
                      },
                      textAlign: 'right',
                    }}
                  >
                    <Typography
                      sx={{
                        width: 'fit-content',
                        zIndex: '1',
                        fontWeight: '400',
                        fontFamily: 'Century Gothic Bold',
                        color: '#fff',
                        textAlign: 'center',
                        textTransform: 'uppercase',
                      }}
                    >
                      {t('list.presenceOf')}
                    </Typography>
                  </Typography>
                </GridItem>
                {snapShot?.intervenants?.map((intervenant, index) => (
                  <GridItem
                    xs={12}
                    sm={12 / snapShot?.intervenants?.length}
                    md={9 / snapShot?.intervenants?.length}
                    lg={9 / snapShot?.intervenants?.length}
                    alignItems={'center'}
                    order={2}
                    sx={{
                      padding: {
                        xs: '5px 0',
                        sm: '40px 0',
                        md: '40px 0',
                        lg: '40px 0',
                      },
                    }}
                  >
                    <Grid
                      container
                      wrap="nowrap"
                      spacing={2}
                      sx={{ marginTop: 0 }}
                    >
                      <Grid>
                        <img
                          src={intervenant.photo}
                          width={'75px'}
                          sx={{
                            border: '2px solid #FFFFFF',
                            borderRadius: '50%',
                          }}
                        />
                      </Grid>
                      <Grid xs zeroMinWidth>
                        <Typography
                          noWrap
                          sx={{
                            width: 'fit-content',
                            zIndex: '1',
                            padding: '10px 0',
                            marginLeft: '10px',
                            fontSize: {
                              xs: '12px !important',
                              sm: '12px !important',
                              lg: '14px !important',
                            },
                            fontFamily: 'Century Gothic bold',
                            textTransform: 'uppercase',
                            color: '#000',
                            float: 'left',
                            width: '100%',
                          }}
                        >
                          {intervenant.nom} {intervenant.prenom}
                        </Typography>
                        <Typography
                          noWrap
                          sx={{
                            width: 'fit-content',
                            zIndex: '1',
                            marginLeft: '10px',
                            fontSize: {
                              xs: '10px !important',
                              sm: '10px !important',
                              lg: '12px !important',
                            },
                            fontFamily: 'Century Gothic',
                            color: '#000',
                            float: 'left',
                            width: '100%',
                          }}
                        >
                          {intervenant.fonction}
                        </Typography>
                      </Grid>
                    </Grid>
                  </GridItem>
                ))}
              </Grid>
            </Container>
          )}
          <Grid item xs={12} sx={{ paddingTop: '0 !important' }}>
            <CardContent>
              <Typography
                sx={{
                  textAlign: 'justify',
                  fontWeight: 'bold',
                  fontSize: { sm: 12, lg: 14 },
                  marginTop: 3,
                }}
              >
                <Markup content={snapShot.description} />
              </Typography>
            </CardContent>
          </Grid>
          <Grid item xs={12} sx={{ paddingTop: '0 !important' }}>
            <CardContent>
              <Typography
                sx={{
                  textAlign: 'justify',
                  fontWeight: 'bold',
                  fontSize: { sm: 12, lg: 14 },
                  marginTop: 3,
                }}
              >
                Liste des inscrits
              </Typography>
              <br></br>
              {snapShot.userAteliers?.length > 0 && (
                <>
                  <Table>
                    <HeaderTab>
                      <TableRow>
                        <TableCell>ID</TableCell>
                        <TableCell>Date inscription</TableCell>
                        <TableCell>Inscrit</TableCell>
                        <TableCell>{t('profile.price')}</TableCell>
                        <TableCell>Statut</TableCell>
                        <TableCell width={15} sx={{ padding: 0 }} />
                      </TableRow>
                    </HeaderTab>
                    <TableBody>
                      {snapShot.userAteliers.map((userAtelier, index) => (
                        <TableRow key={index} sx={{ position: 'relative' }}>
                          <BodyTab type={userAtelier.utilisateur.role}>{userAtelier.utilisateur.id}</BodyTab>
                          <BodyTab type={userAtelier.utilisateur.role}>
                            {moment(userAtelier.date)
                              .tz('Europe/Paris')
                              .format('DD/MM/YYYY HH:mm')}
                          </BodyTab>

                          <BodyTab type={userAtelier.utilisateur.role}>{userAtelier.utilisateur.nom} {userAtelier.utilisateur.prenom} ({userAtelier.utilisateur.email})</BodyTab>
                          <BodyTab type={userAtelier.utilisateur.role}>
                            {(
                              userAtelier.prixPromo *
                              JSON.parse(sessionStorage.getItem('currency'))
                                .currentRate
                            ).toFixed(2)}{' '}
                            {sessionStorage.getItem('currency')
                              ? JSON.parse(sessionStorage.getItem('currency'))
                                  .devise
                              : 'CHF'}
                          </BodyTab>
                          <BodyTab type={userAtelier.utilisateur.role}>{userAtelier.status}</BodyTab>
                          <BlockTab type={userAtelier.utilisateur.role} />
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </>
              )}
            </CardContent>
          </Grid>
        </Grid>
      </Box>

      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
        closeButton={false}
        transition={Slide}
        className={'toast'}
      />
    </div>
  )
}

export default AtelierDetail
